<template>
  <b-input-group
    :prepend="prefix"
    :append="suffix"
    :class="errors.length > 0 ? 'is-invalid' : null"
  >
    <cleave
      class="form-control"
      :value="value"
      :options="number"
      :raw="true"
      :disabled="disabled"
      :placeholder="placeholder"
      @input="input"
      @blur.native="blur"
    />
  </b-input-group>
</template>

<script>
import Cleave from "vue-cleave-component";
import { BInputGroup } from "bootstrap-vue";

export default {
  components: {
    Cleave,
    BInputGroup,
  },
  props: {
    placeholder: {},
    name: {},
    disabled: {},
    value: {},
    prefix: {
      default: "",
    },
    suffix: {
      default: "",
    },
    errors: {
      type: Array,
      default: function () {
        return [];
      },
    },
  },
  data() {
    return {
      number: {
        numeral: true,
        numeralThousandsGroupStyle: "thousand",
      },
    };
  },
  methods: {
    input(value) {
      this.$emit("input", value);
      this.$emit("change", value);
    },
    blur() {
      this.$emit("blur");
    },
  },
};
</script>

<style scoped>
</style>
