<template>
  <b-row>
    <b-col
      :cols="field.cols ? field.cols : 12"
      :md="field.md ? field.md : 4"
      :lg="field.lg ? field.lg : 3"
      v-for="field in fields"
      :key="field.key"
    >
      <slot :name="field.key" :field="field">
        <b-form-group :label="$t(field.label)" :label-for="field.key">
          <b-form-input
            :ref="field.key"
            v-if="field.type === 'text'"
            :id="field.key"
            v-model="data[field.key]"
            :name="field.key"
            :placeholder="$t(field.label)"
            @change="onChange"
          />
          <n-currency-input
            :ref="field.key"
            v-if="field.type === 'currency'"
            :id="field.key"
            v-model="data[field.key]"
            :name="field.key"
            :placeholder="$t(field.label)"
            :prefix="field.prefix"
            :suffix="field.suffix"
            @input="onChange"
          ></n-currency-input>
          <b-form-datepicker
            v-if="field.type === 'date'"
            :ref="field.key"
            v-model="data[field.key]"
            :name="field.key"
            class="mb-1"
            :date-format-options="{
              year: 'numeric',
              month: 'short',
              day: '2-digit',
            }"
            v-bind="labels[$i18n.locale] || {}"
            :locale="$i18n.locale"
            :hide-header="true"
          />
          <n-year-picker
            v-if="field.type === 'nYearPicker'"
            :ref="field.key"
            :name="field.key"
            :placeholder="$t(field.label)"
            :disabled="field.disabled"
            :disable-type="field.disableType"
            v-model="data[field.key]"
          />
          <n-async-multi-select
            v-if="field.type === 'nAsynMultiSelection'"
            v-model="data[field.key]"
            :ref="field.key"
            :name="field.key"
            :init-options="data[field.key]"
            :repository="field.repository"
            :selection-key="field.selectionKey"
            :selection-label="field.selectionLabel"
            :readonly="field.disabled"
            @change="onChange"
          ></n-async-multi-select>

          <n-async-single-select
            :ref="field.key"
            v-if="field.type === 'nAsynSingleSelection'"
            v-model="data[field.key]"
            :name="field.key"
            :init-options="data[field.key]"
            :repository="field.repository"
            :selection-key="field.selectionKey"
            :selection-label="field.selectionLabel"
            :clearable="field.clearable"
            :query="field.query"
            @change="onChange"
          ></n-async-single-select>
          <n-single-select
            v-if="field.type === 'NSingleSelection'"
            v-model="data[field.key]"
            :ref="field.key"
            :name="field.key"
            :init-options="data[field.key]"
            :options="field.options"
            :selection-key="field.selectionKey"
            :selection-label="field.selectionLabel"
            :readonly="field.disabled"
            @input="onChange"
          ></n-single-select>
          <b-form-radio-group
            :ref="field.key"
            v-if="field.type === 'radio'"
            :id="field.key"
            v-model="data[field.key]"
            :disabled="field.disabled"
            @change="onChange"
            buttons
            button-variant="outline-primary rounded-0"
            class="flex-wrap"
          >
            <b-form-radio
              :value="option.value"
              v-for="option in field.options"
              :key="option.value"
              >{{ $t(option.text) }}</b-form-radio
            >
          </b-form-radio-group>
        </b-form-group>
      </slot>
    </b-col>
  </b-row>
</template>

<script>
import {
  BFormGroup,
  BFormInput,
  BFormRadioGroup,
  BFormRadio,
  BRow,
  BCol,
  BFormDatepicker,
} from "bootstrap-vue";
import NAsyncSingleSelect from "@/components/NAsyncSingleSelect";
import NAsyncMultiSelect from "@/components/NAsyncMultiSelect";
import NSingleSelect from "@/components/NSingleSelect";
import NDateInput from "@/components/NDateInput";
import NCurrencyInput from "@/components/NCurrencyInput";
import NYearPicker from "@/components/NYearPicker";

export default {
  components: {
    BFormGroup,
    BFormInput,
    BFormRadioGroup,
    BFormRadio,
    BRow,
    BCol,
    NAsyncSingleSelect,
    NDateInput,
    BFormDatepicker,
    NSingleSelect,
    NCurrencyInput,
    NAsyncMultiSelect,
    NYearPicker,
  },
  props: {
    value: {},
    fields: {
      type: Array,
      default: function () {
        return [];
      },
    },
  },
  data() {
    return {
      data: {},

      labels: {
        en: {
          labelHelp: "",
        },
        km: {
          labelNoDateSelected: "មិនទាន់ជ្រើសរើសថ្ងៃ",
          labelHelp: "",
        },
      },
    };
  },
  computed: {},
  mounted() {
    if (this.value) {
      this.data = this.value;
    }
  },
  methods: {
    reset() {
      this.fields.forEach((element) => {
        if (
          this.$refs[element.key] &&
          typeof this.$refs[element.key][0].reset === "function"
        ) {
          this.$refs[element.key][0].reset();
        }
        this.data[element.key] = null;
      });
    },
    onChange() {
      this.$emit("input", this.data);
    },
  },
};
</script>

<style scoped></style>
