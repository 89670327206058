<template>
  <b-card no-body v-if="fields.length">
    <b-card-header class="cursor-pointer" v-b-toggle.search v-if="showHeader">
      <b-row class="w-100" align-v="center">
        <b-col align-self="center">
          <b>
            <feather-icon icon="FilterIcon" size="18" />
            {{ $t("general.filter") }}
          </b>
        </b-col>
        <b-col>
          <feather-icon
            v-if="!visible"
            icon="ChevronDownIcon"
            size="16"
            v-b-tooltip.hover
            class="float-right"
          />
          <feather-icon
            v-if="visible"
            icon="ChevronUpIcon"
            size="16"
            v-b-tooltip.hover
            class="float-right"
          />
        </b-col>
      </b-row>
    </b-card-header>
    <b-collapse id="search" v-model="visible" class="m-2">
      <slot></slot>
      <div class="float-right w-100 mt-2">
        <b-row>
          <b-col class="text-right">
            <slot name="front-button"></slot>
            <b-button
              variant="info"
              @click="toggleArchived"
              class="mr-1"
              type="button"
              :disabled="loading"
              v-if="showToggleArchived"
            >
              {{
                archived ? $t("button.hideArchived") : $t("button.showArchived")
              }}
            </b-button>
            <b-button
              variant="success"
              @click="exportPdf"
              class="mr-1"
              type="button"
              :disabled="loading"
              v-if="showExportPdf"
            >
              {{ $t("button.exportPdf") }}
            </b-button>
            <b-button
              variant="success"
              @click="exportExcel"
              class="mr-1"
              type="button"
              :disabled="loading"
              v-if="showExportExcel"
            >
              {{ $t("button.exportExcel") }}
            </b-button>
            <b-button
              variant="primary"
              @click="search"
              v-shortkey="['enter']"
              @shortkey="search"
              class="mr-1"
              :disabled="loading"
            >
              {{ $t("button.search") }}
            </b-button>
            <b-button
              variant="outline-secondary"
              @click="reset"
              v-shortkey="['ctrl', 'esc']"
              @shortkey="reset"
              :disabled="loading"
            >
              {{ $t("button.reset") }}
            </b-button>
          </b-col>
        </b-row>
      </div>
    </b-collapse>
  </b-card>
</template>

<script>
import {
  BButton,
  BCard,
  BCardHeader,
  BCol,
  BCollapse,
  BFormInput,
  BInputGroup,
  BInputGroupPrepend,
  BRow,
  BSpinner,
  VBToggle,
} from "bootstrap-vue";

export default {
  components: {
    BButton,
    BSpinner,
    BCard,
    BCardHeader,
    BRow,
    BCol,
    BCollapse,
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,
  },
  directives: {
    "b-toggle": VBToggle,
  },
  props: {
    variant: {},
    type: {},
    loading: {
      type: Boolean,
      default: false,
    },
    showHeader: {
      type: Boolean,
      default: true,
    },
    showExportExcel: {
      type: Boolean,
      default: false,
    },
    showExportPdf: {
      type: Boolean,
      default: false,
    },
    showToggleArchived: {
      type: Boolean,
      default: false,
    },
    archived: {
      type: Boolean,
      default: false,
    },
    fields: {
      type: Array,
      default: function () {
        return [];
      },
    },
    defaultVisible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      visible: false,
    };
  },
  created() {
    if (this.defaultVisible) {
      this.visible = true;
    } else {
      this.checkVisible();
    }
  },
  methods: {
    checkVisible() {
      for (let i = 0; i < this.fields.length; i++) {
        let element = this.fields[i];
        if (this.$route.query && this.$route.query[element.key]) {
          this.visible = true;
          break;
        }
      }
    },
    exportExcel() {
      this.$emit("excel");
    },
    exportPdf() {
      this.$emit("pdf");
    },
    search() {
      this.$emit("search");
    },
    reset() {
      this.$emit("reset");
    },
    toggleArchived() {
      this.$emit("toggle-archived");
    },
  },
};
</script>

<style scoped>
</style>
